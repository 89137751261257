/* eslint-disable no-unused-vars */
import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Sponsors from "../components/sponsors"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

import image from "../../static/macht.png"
const Heading = styled.h1`
  padding: 0 1rem;
  max-width: 1100px;
`
const Paragraph = styled.h2`
  padding: 0 1rem;
`
const A = styled.a`
  color: red;
`
const ParagraphSmall = styled.p`
  padding: 0 1rem;
`
const Container = styled.div`
  margin-bottom: 1rem;
`
const Header = styled.img`
  height: 400px;
  width: 100vw;
  object-fit: contain;
  @media only screen and (max-width: 800px) {
    height: 200px;
  }
`
class Stream extends React.Component {
  render() {
    return (
      <Layout location={this.props.location.pathname}>
        <SEO title="Tickets" />
        <Container>
          <div style={{ height: "500px", width: "500px" }}>
            <div id="rt-03abc4dd54e591a360b307f5af1e4296"></div>{" "}
            <script src="https://rumbletalk.com/client/?orDXupWL"></script>
          </div>
        </Container>
      </Layout>
    )
  }
}
export default Stream
